import React from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import './videos-section.scss'
// import Swiper from 'react-id-swiper'
// import 'react-id-swiper/lib/styles/scss/swiper.scss'

const VideosSection = props => {
  const { allContentfulVideos } = useStaticQuery(
    graphql`
      query {
        allContentfulVideos {
          edges {
            node {
              videosUrls {
                internal {
                  content
                }
              }
            }
          }
        }
      }
    `
  )

  const common = allContentfulVideos.edges[0].node
  const videos = common.videosUrls

  return videos ? (
    <div>
      {videos.map((entry, index) => (
        <div
          key={index}
          className="skills py-5"
          style={
            index % 2
              ? props.theme.secondaryBackgroundColor
              : props.theme.tertiaryBackgroundColor
          }
        >
          <div className="container">
            <center>
              <h3
                className="font-weight-bold text-center"
                style={
                  index % 2 ? { color: 'white' } : props.theme.primaryColor
                }
              >
                {JSON.parse(entry.internal.content).title}
              </h3>
              <iframe
                title="youtube-component"
                className="youtube-component"
                type="text/html"
                src={
                  'https://www.youtube.com/embed/' +
                  JSON.parse(entry.internal.content).videoId +
                  '?enablejsapi=1&origin=https://av-site.com'
                }
                frameBorder="0"
              ></iframe>
            </center>
          </div>
        </div>
      ))}
    </div>
  ) : null
}

export default withTheme(VideosSection)
